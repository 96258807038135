import React, { useState } from "react";

function Last() {
  return (
    <div className="Last">
      <iframe
        className="fullscreen-iframe"
        src="https://my.spline.design/3dtextbluecopy-df2fbf1b3437c3c80c80e82094d8b972/"
        title="Embedded Spline Design"
        frameBorder="0"
      ></iframe>
    </div>
  );
}

export default Last;
