import React, { useContext, useEffect } from "react";
import { AppContext } from "../home";

function GameOver() {
  const { currAttempt, gameOver, correctWord } = useContext(AppContext);

  useEffect(() => {
    if (currAttempt == correctWord) {
      // Trigger an alert when the word is correctly guessed
      alert("Congratulations! You correctly guessed the word!");
    }
  }, [gameOver.guessedWord]);

  return (
    <div className="gameOver">
      <h3>
        {gameOver.guessedWord
          ? "You Correctly Guessed the Wordle"
          : "You Failed to Guess the Word"}
      </h3>
      <h1>Correct Word: {correctWord}</h1>
      {gameOver.guessedWord && (
        <h3>You guessed in {currAttempt.attempt} attempts</h3>
      )}
    </div>
  );
}

export default GameOver;
